body {
  background: -moz-linear-gradient(
          45deg,
          rgba(42, 27, 161, 0.7),
          rgba(29, 210, 177, 0.7) 100%
  );
  background: -webkit-linear-gradient(
          45deg,
          rgba(42, 27, 161, 0.7),
          rgba(29, 210, 177, 0.7) 100%
  );
  background: -webkit-gradient(
          linear,
          45deg,
          from(rgba(42, 27, 161, 0.7)),
          to(rgba(29, 210, 177, 0.7))
  );
  background: -o-linear-gradient(
          45deg,
          rgba(42, 27, 161, 0.7),
          rgba(29, 210, 177, 0.7) 100%
  );
  background: linear-gradient(
          45deg,
          rgba(42, 27, 161, 0.7),
          rgba(29, 210, 177, 0.7) 100%
  );
    scroll-snap-type: y mandatory;
}

#abt, #head, #apppage, #aboutpage, #projectpage {
    scroll-snap-align: start;
}
#apppage .view, #aboutpage .view, #projectpage .view{
    min-height: 100vh;
    color: white;
}


@font-face {
    font-family: hand-font;
    src: url(./fonts/Dosis-Medium.ttf);
}
@font-face {
    font-family: hand-font-small;
    src: url(./fonts/Dosis-Light.ttf);
}
@font-face {
    font-family: hand-font-bold;
    src: url(./fonts/Dosis-Bold.ttf);
}
@font-face {
    font-family: hand-font-regular;
    src: url(./fonts/Dosis-Regular.ttf);
}
@font-face {
    font-family: Worksans-Reg;
    src: url(./fonts/WorkSans-Medium.ttf);
}
.hand-font {
    font-family: hand-font, sans-serif;
}
.light-text-shadow {
    text-shadow: 3px 3px 9px rgba(150, 150, 150, 0.6);
}
.tyler-wannamaker {
    font-size: 8vh;
}
.developer {
    font-family: hand-font-small, sans-serif;
    font-size: 40px;
}
@media only screen and (min-width: 600px) {
    .tyler-wannamaker {
        font-size: 110px
    }
}

#about-header-text, #project-header-text {
    font-family: hand-font-bold, sans-serif;
    color: white;
    font-size: 8vh;
    text-align: center;
}
.experience-row {
    padding-top:50px;
    text-align: center;
    margin-left: 3vw;
    margin-right: 3vw;
}
.experience-col {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    z-index: -2;
}
.exp-icon, .exp-icon-phantom {
    display:flex;justify-content:center;align-items:center;
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    width: 150px;
    padding: 30px;
    border-radius: 50%;
    background-color: whitesmoke;
    font-size: 80px;
    z-index: -50;
    position:relative;
}
.exp-icon-carousel {
    height: 90px;
    width: 90px;
    z-index: 4;
}
.caros {
    height: 150px;
    width: 150px;
    padding: 30px;
    margin-left: auto;
    margin-right: auto;
    background-color: whitesmoke;
    border-radius: 50%;
 }
.top-z {
    z-index: 10;
    position: relative;
}
.exp-icon-container {
    margin: 20px;

}
.exp-title {
    font-family: hand-font, sans-serif;
    font-size: 35px;
}

.exp-desc {
    font-family: hand-font, sans-serif;
    font-size: 25px;
}
.container-scroll {
    width: calc(100vw - (100vw - 100%));
    height: 100vh;
    text-align: center;
    scroll-snap-type: y mandatory;
    scroll-padding: 40px;
    overflow-y: scroll;
}

.child-scroll {
    height: 100%;
    scroll-snap-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pointer-row {
    position:relative;
    top: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -150px;
    height: 400px;
}
.pointer-row:hover {
    cursor: pointer;
}
.pointer-icon {
    font-size: 5vh;
}
#project-head {
    font-family: hand-font-bold, sans-serif;
    text-align: center;
    font-size: 8vh
}
#projectpage {
    padding-top: 17vh;
}
#project-subhead {
    font-family: hand-font-small, sans-serif;
    font-size: 30px;
    text-align: center;
}
.project-list-row {
    padding: 3vw;
    align-items: flex-start;
}
.project-card {
    background-color: whitesmoke;
    border-radius: 9px;
    text-align: center;
    color: black;
    max-width: 400px;
    cursor: pointer;
    margin: 4vh 2vw;
}

.project-card-header {
    padding: 20px;
    font-size: 50px;
    font-weight: 600;
    font-family: hand-font-bold, sans-serif;
}

.project-card-image {
    width: 120%;
    margin-left: -10%;
    height: 300px;
    object-fit: cover;
    box-shadow: 1px 1px 5px black;
    border-radius: 10px;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.project-card-body {
    padding: 20px;
}

.project-card-body-text {
    font-family: hand-font, sans-serif;
    font-size: 25px;
}
.category-badge {
    margin: 9px;
    font-size: 15px;
}
.project-side {
    font-family: hand-font, sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 7vh;
}
#footer {
    font-family: hand-font-small, sans-serif;
    color: white;
    padding: 2vw 2vw 4vw;
    background-color: rgba(20, 20, 20, .7);
}
#email {
    letter-spacing: 2px;
}
#footer-left {
    text-align: center;
}
.footer-about {
    max-width: 500px;
    margin-left:auto;margin-right:auto;
    letter-spacing: 1px;
}
.email-div {
    font-size: max(25px, 3vw);
}
#email-btn {
    position: absolute;
    top:20px;
    right: 20px;
    width: 70px;
    height: 70px;
    font-size: 40px;
    border-radius: 35px;
    padding: 1px 0 0;
}
#linkedin-btn {
    position: absolute;
    top:70px;
    right: 20px;
    width: 70px;
    height: 70px;
    font-size: 40px;
    border-radius: 35px;
    padding: 1px 0 0;
}
#email-btn-container {
    position: absolute;
    top: 20px;
    right: 20px;
}

#linkedin-btn-container {
    position: absolute;
    top: 70px;
    right: 20px;
}
